









import Vue from "vue";
import NavSideBar from "@/components/candidate/NavSideBar.vue";
import { process_tenant_registration } from "@/utils/principle_assessments";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "CandidateWrapper",
  components: { NavSideBar },
  data() {
    return {};
  },
  async mounted() {
    await process_tenant_registration(this.get_user_details.id);
  },
  computed: {
    ...mapGetters("auth", {
      get_user_details: GET_USER_DETAILS
    })
  },
  methods: {}
});
